@import 'src/styles/variables';
@import 'src/styles/mixins';

.documentsList {
  align-self: baseline;
  list-style: none;
  margin-bottom: 16px;

  @include sm {
    margin-bottom: 24px;
  }
}

.documentListItem {
  font-size: 16px;
  line-height: 28px;
  color: $black100;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background-color: $blue;
    margin-right: 8px;
  }
}

.filesRequirementsList {
  width: 100%;
  list-style: inside;
  font-size: 12px;
  line-height: 20px;
  color: $black60;
  margin: 8px 0 16px;

  @include sm {
    font-size: 14px;
    line-height: 22px;
    margin: 16px 0 24px;
  }
}

.button {
  margin: 32px 0 20px;
}
