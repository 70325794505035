@import 'src/styles/mixins';
@import 'src/styles/variables';

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-top: 32px;
}

.verified {
  margin: 32px auto 0;
  display: flex;
  align-items: center;
}

.checkIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.verifiedLabel {
  color: $green;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
}
