.checkIcon {
  width: 56px;
  height: 56px;
}

.subtitle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.flowContent {
  margin-bottom: 32px;
}
