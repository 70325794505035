@import 'src/styles/variables';
@import 'src/styles/mixins';

.modelViewer {
  overflow: hidden;
}

.modelViewer model-viewer {
  width: 180%;
  height: 378px;
  left: -40%;
  margin: -50px auto;

  @include sm {
    height: 500px;
    scale: 1;
  }
}

.hidden {
  display: none;
}

.loader {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  position: absolute;
}
