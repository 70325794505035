@import 'src/styles/variables';
@import 'src/styles/mixins';

.survey {
  margin-bottom: 32px;
}

.question {
  margin-bottom: 8px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $black10;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 3px 10px 0 rgba(48, 52, 101, 0.07);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.checkbox {
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid $black10;
  margin-right: 12px;
}

.greenCheck {
  margin-right: 12px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
}

.optionText {
  @include body-text(md, $semiBoldFontWeight);
}
