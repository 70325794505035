@import 'src/styles/variables';
@import 'src/styles/mixins';

.formInput:not(:last-child) {
  @include formInputMargin;
}

.inputs {
  margin-top: 8px;
  margin-bottom: 24px;
  width: 100%;
}

.checkbox {
  margin-bottom: 16px;
}

.secondEmployerButton > * {
  display: flex;
  margin-bottom: 24px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $black20;
  margin-top: 12px;
  margin-bottom: 36px;
}

.cross {
  margin-right: 8px;
  font-size: 24px;
  transform: rotate(45deg);
}

.additionalIncomeText {
  font-size: 12px;
}
